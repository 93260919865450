<template>
  <common-dialog
    :show="show"
    :loading="loading"
    @close="close"
  >
    <template #header>
      <span>Возврат на платежное поручение</span>
    </template>
    <template #body>
      <v-row>
        <v-col cols="3">
          <label>Остаток к возврату</label>
          <input-numeric
            v-model="params.remains"
            disabled
            background-color="grey lighten-2"
            :fraction="2"
            suffix="BYN"
          />
        </v-col>
        <v-col cols="4">
          <label>Вид платежа</label>
          <v-autocomplete
            :value="paymentCode"
            disabled
            auto-select-first
            :items="paymentCodes"
            outlined
            dense
            item-text="search"
            item-value="code"
            hide-details="auto"
            background-color="grey lighten-2"
            :filter="filterBySearchField"
          />
        </v-col>
        <v-col cols="5">
          <label>УНП (гр. 14 декларант)</label>
          <div class="d-flex">
            <v-text-field
              v-model="owner"
              hide-details="auto"
              class="ba-0 rounded-r-0"
              outlined
              dense
              placeholder="Поиск"
              clearable
              @keyup.enter="search"
            />
            <v-btn
              color="#5CB7B1"
              height="auto"
              class="elevation-0 my-0 rounded-l-0 white--text"
              x-large
              min-width="24px"
              @click="search"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="8">
          <h4>Выберите платежное поручение</h4>
          <v-radio-group
            v-model="state.payment_order_id"
            hide-details
          >
            <v-simple-table class="scrollbar">
              <template #default>
                <tbody>
                  <tr
                    v-for="(payment) in payments"
                    :key="payment.id"
                    :class="{
                      'teal lighten-5': payment.code === params.item.code
                    }"
                  >
                    <td>
                      <v-radio
                        color="teal"
                        :value="payment.id"
                        class="mb-0"
                        @change="onPaymentOrderSelect(payment)"
                      />
                    </td>
                    <td>
                      {{ payment.remains }} {{ payment.currency_letter }}
                    </td>
                    <td>
                      {{ payment.code }}
                    </td>
                    <td>
                      <span class="teal--text">{{ payment.payment_doc_number }}</span>
                    </td>
                    <td>
                      от {{ payment.prettyDate }}
                    </td>
                    <td>
                      <v-tooltip
                        v-if="payment.bank_name"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ payment.bank_name }}
                            <v-icon
                              small
                            >
                              mdi-information
                            </v-icon>
                          </span>
                        </template>
                        <span>{{ payment.bankShortName }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-radio-group>
        </v-col>
        <v-col cols="4" />
        <v-col
          cols="3"
        >
          <label>Сумма возврата</label>
          <input-numeric
            v-model="state.sum"
            :error="!isSumValid"
            :fraction="2"
            suffix="BYN"
          />
        </v-col>
        <v-col cols="1">
          <label>Тип</label>
          <v-text-field
            v-model="state.payment_type"
            outlined
            dense
            disabled
            background-color="grey lighten-2"
            hide-details
          />
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-4">
        <v-btn
          elevation="0"
          color="#EDEDED"
          class="mr-2"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          :disabled="!isSumValid"
          color="#5CB7B1"
          elevation="0"
          @click="submit"
        >
          Вернуть
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import moment from "moment";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {eventBus} from "@/bus";
import {UPDATE_DEBIT_PAYMENTS_TABLE} from "@/components/kdt/graph-b/events";
import {getNextPaymentType, paymentOrderDebitDefaultState} from "@/helpers/payment-order";
import CommonDialog from "@/components/shared/common-dialog.vue";


export default {
  components: {CommonDialog, InputNumeric},
  mixins: [closeOnEscapeMixin, filterBySearchFieldMixin],
  props: {
    show: {
      required: true,
      type: Boolean
    },
    params: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      state: paymentOrderDebitDefaultState(),
      payments: [],
      paymentCodes: [],
      banks: [],
      searchValue: "",
    }
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      user: 'auth/getUser'
    }),
    isIndividual() {
      return this.selected.declarant.declarant_json.individual
    },
    owner: {
      get() {
        return this.searchValue;
      },
      set(val) {
        this.searchValue = val;
      },
    },
    paymentCode() {
      return this.params?.item?.code || null
    },
    isSumValid() {
      if (this.state.payment_order_id) {
        const payment = this.payments.find(p => p.id === this.state.payment_order_id)
        return this.state.sum <= (payment?.remains || 0)
      }
      return false
    }
  },
  created() {
    this.setState()
    this.loadBanks().then(this.fetchPaymentOrders)
    this.fetchNsiPaymentCodes()
    this.setOwner()
  },
  methods: {
    setOwner() {
      if (this.isIndividual) {
        this.searchValue = this.selected.declarant.declarant_json.personal_number;
      } this.searchValue = this.selected.declarant.declarant_inn;
    },
    loadBanks() {
      return this.$store.dispatch('catalogs/loadBankBIC').then((banks) => {
        this.banks = banks
        return banks;
      }).catch(() => [])
    },
    setLoading(loading) {
      this.loading = loading
    },
    onPaymentOrderSelect(payment) {
      this.setPaymentSum(payment)
      this.setPaymentType(payment)
    },
    setPaymentType(payment) {
      const paymentType = payment.payment_type
      if(payment.code !== this.paymentCode){
        this.state.payment_type = getNextPaymentType(paymentType)
      }else {
        this.state.payment_type = paymentType
      }
    },
    setPaymentSum(payment) {
      this.state.sum = payment.remains >= this.params.remains ? this.params.remains : payment.remains
    },
    setState() {
      this.state.payment_order_id = this.params.selectedPaymentOrderId
      this.state.code = this.paymentCode
    },
    fetchNsiPaymentCodes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.paymentCodes = res.data.list.map((i) => ({...i, search: `${i.code}-${i.name}`}))
      })
    },
    fetchPaymentOrders() {
      this.$store.dispatch('kdt/getPaymentOrderListFromDt', {code: this.params.item.code})
        .then(({data}) => this.payments = this.prettifyOrders(data))
        .then(this.updateSumAndType)
        .catch(() => {
          this.$error("Не удалось загрузить ПП")
        })
    },
    updateSumAndType() {
      if (this.state.payment_order_id) {
        const payment = this.payments.find(payment => payment.id === this.state.payment_order_id)
        if (payment) {
          this.setPaymentSum(payment)
          this.setPaymentType(payment)
        }
      }
    },
    prettifyOrders(orders) {
      return orders.map(order => {
        const bank = this.banks.find(bank => bank.CDBank === order.bank_name)
        return {
          ...order,
          prettyDate: moment(order.payment_date).isValid() ? moment(order.payment_date).format("DD.MM.YYYY") : null,
          bankShortName: bank?.NmBankShort
        }
      })
    },
    clearState() {
      Object.assign(this.$data.state, paymentOrderDebitDefaultState())
    },
    submit() {
      if (!this.state.payment_order_id) {
        this.$warning("Не выбрано ПП")
        return false;
      }
      if (!this.state.payment_type) {
        this.$warning("Отсутствует тип платежа")
        return false;
      }
      const payload = convertEmptyStringsToNull({
        ...this.state,
        declaration_id: this.selected.id,
        direction: 1
      })
      this.setLoading(true)
      this.$store.dispatch('kdt/createPaymentOrderDebit', payload)
        .then(() => this.$info("Возврат создан"))
        .then(() => eventBus.$emit(UPDATE_DEBIT_PAYMENTS_TABLE))
        .then(() => this.close())
        .catch((err) => {
          const DEFAULT_MSG = "Не удалось создать возврат"
          const msg = err.response.status === 400 ? err.response.data || DEFAULT_MSG : DEFAULT_MSG
          this.$error(msg)
        })
        .finally(() => this.setLoading(false))
    },
    close() {
      this.$emit('update:show', false)
    },
    search() {
      this.fetchPaymentOrders();
    }
  }
}
</script>

<style>
.scrollbar {
  overflow: auto;
  max-height: 250px;
  width: 560px;
}
</style>
